<template>
  <div>
    <v-toolbar color="#002663">
      <div class="font-abel-20px white--text ml-3">Monarch</div>

      <v-spacer></v-spacer>

      <div class="font-abel-20px ml-2" :style="{ color: '#ffb608' }">
        Atlas Air
      </div>
    </v-toolbar>

    <div class="spacer-vertical"></div>

    <v-container fluid>
      <v-row dense>
        <v-col cols="1"> </v-col>

        <v-col cols="2">
          <div class="d-flex justify-start">
            <v-img
              :max-width="150"
              :aspect-ratio="2.9"
              src="https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_logo.png"
              alt="Atlas logo"
            >
            </v-img>
          </div>
        </v-col>

        <v-col cols="6"> </v-col>

        <v-col cols="2" justify="end">
          <div class="d-flex justify-end">
            <v-img
              :max-width="150"
              :aspect-ratio="2.9"
              src="https://s3.amazonaws.com/monarch.atlasair.com.resources/atlas_logo_giant.png"
              alt="Atlas logo"
            >
            </v-img>
          </div>
        </v-col>

        <v-col cols="1"> </v-col>
      </v-row>

      <v-row dense justify="space-around">
        <v-col cols="10">
          <v-img
            :max-height="600"
            :aspect-ratio="16 / 9"
            src="https://s3.amazonaws.com/monarch.atlasair.com.resources/landing_page.jpg"
            alt="B747-8"
          >
            <div class="font-abel-24px white--text text-center mt-7">
              Welcome to Atlas Air’s Ground Ops Scheduling
            </div>

            <div class="d-flex justify-center font-abel-30px atlas-blue">
              <span v-if="!expired"
                >Next release in: {{ displayDays }} day(s)
                {{ displayHours }} hour(s) {{ displayMinutes }} min
                {{ displaySeconds }} sec
              </span>
              <span v-else>{{ `Delayed` }}</span>
            </div>
          </v-img>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="1"> </v-col>

        <v-col cols="10">
          <p class="font-abel-12px text-center">
            {{ disclaimer }}
          </p>
        </v-col>

        <v-col cols="1"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    this.showRemaining();
  },
  data() {
    return {
      expired: false,
      countDownDate: new Date("Mar 31, 2024 23:59:59").getTime(),
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      disclaimer: `Flight schedule information and other related station and aircraft details are made available 
        for the convenience of Atlas Air and Vendor Staff. Atlas Air cannot and does not guarantee that all
        information is current, complete or totally accurate at any given time. While we strive to provide
        complete, accurate and near real-time flight and schedule information, reliance on this software and the
        flight details herein is solely at the users own risk and Atlas Air disclaims any warranty or indemnity
        involved with the use of the software, whether express or implied.© 2021 - Atlas Air Worldwide Holdings,
        Inc. All Rights Reserved.`,
    };
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
  },
  methods: {
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date();
        const distance = this.countDownDate - now.getTime();

        if (distance < 0) {
          clearInterval(timer);
          this.expired = true;
        }

        //console.log(distance)

        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displayMinutes = minutes < 10 ? "0" + minutes : minutes;
        this.displaySeconds = seconds < 10 ? "0" + seconds : seconds;
        this.displayHours = hours < 10 ? "0" + hours : hours;
        this.displayDays = days < 10 ? "0" + days : days;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.disclaimer {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
}

.home-image {
  border: 1px solid #bebebe;
  box-shadow: 3px 3px 3px #868383c9;
}

.frame {
  border: 1psx solid #bebebe;
  border-radius: 5px;
  box-shadow: 3px 3px 3px #868383c9;
  text-align: center;
  background-color: #0f2656;
  color: #ffb52e;
  width: 230px;
  margin: auto;
}

.dialog-container {
  padding: 10px;
  background-color: #f5f5f5;
}
</style>
